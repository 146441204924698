<template>
  <section class="me">
    <div class="form-container">
      <template v-for="selector in selectorList">
        <div v-if="selector.enabled" :key="selector.name">
          <label
            :for="selector.name"
            v-if="hasAnyLabel"
            :class="getClassSelector(selector)"
          >
            {{ selector.label || "-" }}
            <ToolTip v-if="selector.hint" :title="selector.hint" />
          </label>
          <v-select
            :id="selector.name"
            class="v-select"
            :value="optionValue(selector)"
            @input="optionValue(selector, $event)"
            :placeholder="$tc(selector.placeholder, 1)"
            :getOptionLabel="(i) => itemLabel(selector, i)"
            :options="optionList(selector)"
            :reduce="(i) => i.id"
            :title="selector.hint"
          />
        </div>
      </template>
      <div class="last-column">
        <label
          v-if="hasAnyLabel"
          style="color: transparent;display:block;margin-bottom:5px"
          >-</label
        >
        <span
          class="btn btn-primary hidden-sm hidden-xs"
          :disabled="!isValid"
          @click.stop.prevent="onConfirm"
          :title="$t('confirm')"
        >
          <span><i class="fa fa-check"></i></span>
          <span>
            {{ $t("confirm") }}
          </span>
        </span>
        <span
          class="btn btn-primary  hidden-md hidden-lg"
          :disabled="!isValid"
          @click.stop.prevent="onConfirm"
          :title="$t('confirm')"
          style="width:100%"
        >
          <span><i class="fa fa-check"></i></span>
          <span>
            {{ $t("confirm") }}
          </span>
        </span>
      </div>
      <slot name="customToolbar"></slot>
    </div>
    <!-- <div>{{ nItems }}</div>
    <div>{{ first }}</div> -->
  </section>
</template>

<script>
import {isEqual} from "lodash";
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "DashboardLauncher",
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  components: {
    ToolTip
  },
  data() {
    return {
      selected: {},
      nItems: {},
      first: {}
    };
  },
  computed: {
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    selectorList() {
      return this.config.selector_list;
    },
    deviceList() {
      return (this.$store.getters["dashboard/deviceList"] || []).filter(
        ({connector}) => !connector.base_model
      );
    },
    filteredProcessAreaList() {
      return this.$utils
        .distinct(this.deviceList.map(({process_area}) => process_area))
        .sort((a, b) => (a.name > b.name ? 1 : b.name < a.name ? -1 : 0));
    },
    filteredConnectorList() {
      let lst = this.$utils.distinct(
        this.deviceList.map(({connector}) => connector)
      );
      if (this.selected.process_area) {
        lst = lst.filter(
          ({process_area}) => process_area.id == this.selected.process_area
        );
      }
      return lst.sort((a, b) =>
        a.name > b.name ? 1 : b.name < a.name ? -1 : 0
      );
    },
    filteredDeviceList() {
      let lst = this.$utils.distinct(this.deviceList);
      if (this.selected.connector) {
        lst = lst.filter(
          ({connector}) => connector.id == this.selected.connector
        );
      } else if (this.selected.process_area) {
        lst = lst.filter(
          ({process_area}) => process_area.id == this.selected.process_area
        );
      }
      return lst;
    },
    isValid() {
      return this.selected.connector || this.selected.device ? true : false;
    },
    targetScreen() {
      let screenId = "";
      switch (this.config.screen_source) {
        case "custom":
          screenId = this?.config?.screen_id || "";
          break;
        case "device":
          {
            if (this.selected.device) {
              const item = (this.filteredDeviceList || []).find(
                ({id}) => id == this.selected.device
              );
              if (item) {
                if (item?.screen_id) {
                  screenId = item.screen_id;
                } else {
                  screenId = item.connector.screen_id;
                }
              }
            }
          }
          break;
        case "connector":
          {
            if (this.selected.connector) {
              const item = (this.filteredConnectorList || []).find(
                ({id}) => id == this.selected.connector
              );
              if (item?.screen_id) {
                screenId = item.screen_id;
              }
            }
          }
          break;
        default: {
          screenId = this.$store.getters["dashboard/dashboardScreenId"];
        }
      }
      if (!screenId) {
        screenId = this.$store.getters["dashboard/dashboardScreenId"];
      }
      return screenId;
    },
    hasAnyLabel() {
      return this.selectorList.some(
        ({enabled, label}) => enabled && label !== ""
      );
    }
  },
  watch: {
    nItems(n, o) {
      if (!n || !o || isEqual(n, o)) return;
      console.log(n);
    }
  },
  methods: {
    getClassSelector(selector) {
      if (selector.label != "") {
        return "selector-filled";
      } else {
        return "selector-empty";
      }
    },
    optionValue(selector, value) {
      if (value !== undefined) {
        this.$set(this.selected, selector.name, value);
        this.$nextTick(() => {
          if (
            this.selected.connector &&
            !this.filteredConnectorList.some(
              ({id}) => this.selected.connector == id
            )
          ) {
            this.selected.connector = "";
            this.selected.device = "";
          }
        });
      }
      return this.selected[selector.name] ?? "";
    },
    optionList(selector) {
      let lst = [];
      switch (selector.name) {
        case "process_area": {
          lst = this.filteredProcessAreaList || [];
          break;
        }
        case "connector": {
          lst = this.filteredConnectorList || [];
          break;
        }
        case "device": {
          lst = this.filteredDeviceList || [];
          break;
        }
      }
      if (!selector.show_nulls) {
        lst = lst.filter((i) => this.itemLabel(selector, i) !== "");
      }
      const ord = selector.inverted ? [-1, 1, 0] : [1, -1, 0];
      lst.sort((a, b) => {
        let va = this.$utils.removeDiacritics(this.itemLabel(selector, a));
        let vb = this.$utils.removeDiacritics(this.itemLabel(selector, b));
        return va > vb ? ord[0] : vb > va ? ord[1] : ord[2];
      });
      this.$set(this.nItems, selector.name, lst.length);
      this.$set(this.first, selector.name, lst.length ? lst[0].id : null);
      return lst;
    },
    itemLabel(selector, item) {
      let expression = selector.expression || "${data.name}";
      return this.$root.$formatter.format({
        ...item,
        template: expression
      });
    },
    onConfirm() {
      if (this.mode == "editor" || !this.isValid) return;
      let entry = null;
      if (this.selected.device) {
        const item = (this.filteredDeviceList || []).find(
          ({id}) => id == this.selected.device
        );
        entry = item && {
          connectorId: item.connector.id,
          deviceId: item.id
        };
      } else {
        const item = (this.filteredConnectorList || []).find(
          ({id}) => id == this.selected.connector
        );
        entry = item && {
          connectorId: item.id
        };
      }
      if (entry) {
        this.trigger(entry);
      }
    },
    trigger(entry) {
      this.$root.$emit("custom_action", {
        event: "click",
        action: {
          type: "screen:open",
          title: "screen_open",
          valid_events: ["click"],
          custom_params: true,
          options: {
            screen_id: {
              required: true,
              placeholder: "screen",
              label: "name",
              value: this.targetScreen,
              key: "id",
              options: "screens",
              index: 0
            },
            params: {
              connector_id: entry.connectorId,
              device_id: entry.deviceId
            }
          }
        }
      });
    },
    isSelectorEnabled(selectorName) {
      return this.selectorList.some(
        ({name, enabled}) => name == selectorName && enabled
      );
    },
    init() {
      let connectorId =
        this.$store.getters["dashboard/dashboardEquipmentId"] || "";
      let deviceId = this.$store.getters["deviceId"] || "";
      let connector = null;
      let device = null;
      let entry = {};
      this.selectorList.forEach(({name}) => (entry[name] = ""));
      if (deviceId) {
        device = this.deviceList.find(
          ({id}) => parseInt(deviceId) === parseInt(id)
        );
        if (device) {
          connector = device.connector;
        }
      } else if (connectorId) {
        connector =
          this.deviceList.find(
            ({connector}) => parseInt(connectorId) === parseInt(connector.id)
          )?.connector || null;
      }
      if (connector) {
        if (this.isSelectorEnabled("connector")) {
          entry.connector = connector.id;
        }
        if (
          connector.process_area.id &&
          this.isSelectorEnabled("process_area")
        ) {
          entry.process_area = connector.process_area.id;
        }
        if (device && this.isSelectorEnabled("device")) {
          entry.device = device.id;
        }
        this.$set(this, "selected", entry);
      }
    }
  },
  created() {
    this.init();
  }
};
</script>

<style scoped>
.me {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.form-container {
  width: inherit;
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  justify-content: flex-start /* get it from vars */;
}

label.selector-filled {
  color: initial;
}

.skin-dark label.selector-filled {
  color: #b8c7ce;
}

label.selector-empty {
  color: transparent;
}

.v-select {
  height: 34.6px;
  padding: 0;
  min-width: 220px;
}
.last-column {
  min-width: 220px;
}
.v-select::v-deep .vs__dropdown-toggle {
  cursor: text;
  height: inherit;
}
</style>
