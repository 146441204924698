<template>
  <div class="me html2canvas-ignore" data-html2canvas-ignore>
    <DashboardLauncher
      class="inner-component"
      v-if="panel"
      :config="panel.options"
    >
      <template #customToolbar>
        <span v-if="showCustomToolbar">
          <div class="custom-panel-toolbar">
            <slot name="toolbar"></slot>
          </div>
        </span>
      </template>
    </DashboardLauncher>
  </div>
</template>

<script>
import DashboardLauncher from "@/components/dashboard-launcher.vue";
export default {
  name: "DashboardLauncherPanel",
  props: {
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    showCustomToolbar() {
      return (this?.panel?.toolbar || []).length > 0;
    }
  },
  components: {
    DashboardLauncher
  }
};
</script>

<style scoped>
.me {
  padding: 3px 6px;
  margin: 0;
  overflow: visible !important;
}
.me > .inner-component {
  width: 100%;
}
.custom-panel-toolbar {
  display: inline-block;
}
</style>
